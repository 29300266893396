<template>
  <transition>
    <div class="cardBillDetail" v-loading="fullscreenLoading">
     
      <span class="card-icon">
       <el-button type="primary"  class="themed-button" @click="refresh(true)">获取实时状态</el-button>
        <el-button type="primary"  class="themed-button" @click="clearHistory">清除缓存</el-button>
       </span>
     
       <div class="group">
        <h1 class="title">基础信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
            <tr>
              <td>ICCID</td>
              <td>{{ iccidMark }}</td>
              <td>接入号</td>
              <td>{{ data.phone }}</td>
              <td>短号</td>
                 <td><span v-if="shortIccid">{{shortIccid}}</span>
                 <span v-else>-</span> </td>
                <td>套餐类型</td>
              <td>{{ data.mealTypeStr }}</td>
            </tr>
             <tr>
             <td>过期时间</td>
              <td>{{ data.expireDate }}</td>
              <td>运营商</td>
              <td>{{ data.accountName }}</td>
            <td>网卡状态</td>
              <td>{{data.statusString}}</td>
               <td>代理商</td>
              <td>
                <span v-if="data.agentName">{{ data.agentThreeName }}</span>
                <span v-else>未分配</span>
              </td>
            </tr>
            <tr>
              <td>供应商</td>
              <td>{{ accountEntity.supplier }}</td>
               <td>导入时间</td>
              <td>{{ importTime }}</td>
              <td>激活时间</td>
              <td>{{ data.servCreateDate }}</td>
              <td>套餐生效时间</td>
              <td> <span v-if="res.takeEffectTim">{{ res.takeEffectTime }}</span>
              <span v-else>-</span></td>
            </tr>
            <tr>
              <td>卡类型</td>
              <td>
                <span v-if="data.type == 1">包月</span>
                <span v-else>包年</span>
              </td>
               <td>绑定手机</td>
               <td>
                <span v-if="res.phonebind">{{ res.phonebind }}</span>
                <span v-else>未绑定</span>
              </td>
               <td>流量池</td>
                 <td>{{ accountEntity.flowPool }}</td>
                  <td>IMEI</td>
              <td> <span v-if="data.imei">{{ data.imei }}</span>
               <span v-else>-</span></td>
               
            </tr>
             <tr>
              <td>运营商状态</td>
              <td>{{yunyingstatusspan}}</td>
               <td>当前限速值</td>
              <td><span v-if="res.limitSpeed">{{ res.limitSpeed }}</span>
              <span v-else>-</span>  </td>
              <td>实名状态</td>
               <td>
                  <span v-if="isReal == 1">已实名</span>
                  <span v-else-if="isReal == 2">未实名</span>
                  <span v-else>—未知状态—</span>
                  <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button>
                  <!-- <i class="el-icon-refresh-right" @click="_isRealName"></i> -->
              </td>
               <td>设备在线状态</td>
               <td> <span v-if="deviceOnline">{{deviceOnline}}</span>
               <span v-else>-</span></td>
             </tr>
              <tr>
             
              <td>虚拟卡号</td>
              <td>
                  <span v-if="data.invNo">{{data.invNo}}</span>
              </td>
              <td>实名方式</td>
              <td>{{getRealNameType(accountEntity.realNameType)}}</td>
               <td>首次充值时间</td>
              <td><span v-if="data.enabledTime">{{data.enabledTime}}</span>
              <span v-else>-</span></td>
               <td>
                <span v-if="data.toType == 'Y'">话费</span>
                <span v-else></span>
              </td>
               <td>
                <span v-if="data.toType == _getToTypeValue('Y_')">{{data.balanceMoney}}</span>
                <span v-else></span>
              </td>
              </tr>
               <tr>
                <td>是否轮询</td>
                 <td>
              <span v-if="noLoop"> 不在轮询中</span>
              <span v-else>轮询中</span>
              </td>
              <td>加入不轮询时间</td>
              <td><span v-if="res.loopCreateTime">{{res.loopCreateTime}}</span>
              <span v-else>-</span></td>
               <td>断网状态</td>
               <td style="display:table-cell; vertical-align:middle">
                <span v-html="getCutStatus"></span>
                <el-button  icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="isOperatorFunciton" style="margin-left:3px;"></el-button>
               
               </td>
              <!-- <td> <span v-if="isAloneLimitStop">单独断网<el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="isOperatorFunciton" style="margin-left:3px"></el-button><br></span>  
               <span v-if="isLimitStop">达量断网<el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="isOperatorFunciton" style="margin-left:3px"></el-button><br></span>  -->
               <!-- <span v-if="false">机卡不匹配 <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button><br></span>
               <span v-if="false">机卡分离 <el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="_isRealName" style="margin-left:3px"></el-button></span> -->
               <!-- <span v-if="!isAloneLimitStop&&!isLimitStop">-<el-button icon="el-icon-refresh-right" type="primary" class="themed-button"  @click="isOperatorFunciton" style="margin-left:3px"></el-button></span></td> -->
                 <td>上次轮询时间</td>
              <td> <span v-if="res.lastLoopTime">{{  formatTime(res.lastLoopTime) }}</span>
              <span v-else>-</span> </td>
               </tr>
           </table>
        </div>
      </div>
      <div class="group">
        <h1 class="title">流量信息</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%;text-align:left">
          <tr>
              <td>套餐总流量</td>
              <td>{{totalFlowId}}</td>
              <td>已使用流量</td>
              <td>{{ parseFloat(data.totalBytesCnt).toFixed(2)}}MB</td>
               <td>剩余流量</td>
                 <td>{{parseFloat(surplusFlowId).toFixed(2)}}</td>
              <td>基础套餐</td>
              <td>{{ data.setMealName }}</td>
            </tr>
            <tr v-if="isShow">
              <td>总语音(分钟)</td>
              <td><span>{{data.voiceSize}}分钟</span></td>
              <td>已使用语音(分钟)</td>
              <td><span>{{voiceUsed}}</span></td>
              <td>剩余语音(分钟)</td>
              <td><span>{{voiceRemaining}}</span></td>
               <td></td>
               <td></td>
            </tr>
            <tr>
              <td>增加已使用流量百分比
                <span>{{_getStrategy(res.strategyType)}}</span>
              </td>
              <td>
                <span v-html="curStrategyFormat"></span>
              </td>
              <td>已使用流量(真)</td>
               <td>{{ parseFloat(data.orginTotalBytesCnt).toFixed(2) }}MB</td>
               <td v-if="isShow">增加已使用语音百分比</td>
                <td v-if="isShow">
                  <span v-if="res.voiceAddDiscount">{{res.voiceAddDiscount}}</span>
                  <span v-else>未设置</span>
                </td>
                 <td v-if="isShow">已使用语音(真)</td>
                 <td v-if="isShow"><span>{{origVoiceUsed}}</span></td>
            </tr>
           </table>
        </div>
      </div>
       <div class="group">
        <h1 class="title">当前套餐</h1>
        <div>
           <table cellspacing="0" cellpadding="0" style="width: 100%">
             <tr>
             <td v-html="currentMeal"></td>
             </tr>
           </table>
        </div>
      </div>
      <div class="group">
        <h1 class="title">常规操作</h1>
        <div>
          <ul class="clearfix">
            <li @click="mealRecharge">
              <img src="./1.png" alt="" />
              <p>套餐充值</p>
            </li>
            <li @click="activate">
              <img src="./2.png" alt="" />
              <p>激活</p>
            </li>
            <li @click="cardRecovery">
              <img src="./3.png" alt="" />
              <p>保号复机</p>
            </li>
            <li @click="cardStop">
              <img src="./4.png" alt="" />
              <p>保号停机</p>
            </li>
            <li @click="limitStop">
              <img src="./4.png" alt="" />
              <p>设置达量断网</p>
            </li>
            <li @click="limitStopRecovery">
              <img src="./4.png" alt="" />
              <p>达量断网复机</p>
            </li>
            <li @click="flowDetail">
              <img src="./4.png" alt="" />
              <p>流量详单</p>
            </li>
            <li @click="updateExpire">
              <img src="./5.png" alt="" />
              <p>更改过期时间</p>
            </li>
            <li @click="setThreshold">
              <img src="./6.png" alt="" />
              <p>更新阈值</p>
            </li>
            <li @click="toNewCard">
              <img src="./7.png" alt="" />
              <p>转新卡</p>
            </li>
            <li @click="deviceCardBound">
              <img src="./8.png" alt="" />
              <p>机卡绑定</p>
            </li>
            <li @click="stopRecoveryRecord">
              <img src="./9.png" alt="" />
              <p>停复机记录</p>
            </li>
            <li @click="orderRecord">
              <img src="./9.png" alt="" />
              <p>往期订单</p>
            </li>
            <li @click="singleLimit">
                        <img src="./7.png" alt="">
                        <p>单卡限速</p>
                    </li>
            <li @click="tamperRecovery">
              <img src="./7.png" alt="" />
              <p>窜卡复机</p>
            </li>
            <li @click="noLoopcard">
              <img src="./7.png" alt="" />
              <p>不轮询网卡</p>
            </li>
            <li @click="modifyFlow">
              <img src="./7.png" alt="" />
              <p>增减流量</p>
            </li>
            <li @click="whiteNubmerSet">
              <img src="./7.png" alt="" />
              <p>白名单设置</p>
            </li>
            <li @click="allowRealname">
              <img src="./7.png" alt="" />
              <p>允许实名(GD)</p>
            </li>
            <li @click="openVoice">
              <img src="./7.png" alt="" />
              <p>开通语音(GD)</p>
            </li>
            <li @click="delOnlyLoop">
              <img src="./7.png" alt="" />
              <p>删除仅轮询</p>
            </li>
            <li @click="lifeCycleChange">
              <img src="./7.png" alt="" />
              <p>转正使用</p>
            </li>
            <li @click="changeVirtual">
              <img src="./2.png" alt="" />
              <p>切换量级级别</p>
            </li>
            <li @click="remarkDetail">
              <img src="./7.png" alt="" />
              <p>备注</p>
            </li>
            <li @click="gateSetting">
              <img src="./4.png" alt="" />
              <p>单卡闸值设置</p>
            </li>
            <li @click="advanceLife">
              <img src="./4.png" alt="" />
              <p>套餐提前生效</p>
            </li>
            <li @click="cancelDxDiscon">
              <img src="./4.png" alt="" />
              <p>取消单独断网</p>
            </li>
            <li @click="unbindRealName">
              <img src="./4.png" alt="" />
              <p>实名解绑</p>
            </li>
             <li @click="returnToZero"  >
              <img src="./7.png" alt="" />
              <p>退货清零</p>
            </li>
          </ul>
        </div>
      </div>

      <el-dialog title="套餐充值" :visible.sync="dialogAddPackage" v-if="dialogAddPackage">
        <add-package
          :addPackageData="addPackageData"
          :curInfor="data"
          @close="dialogAddPackage = false"
        ></add-package>
      </el-dialog>
      <el-dialog title="设置达量" :visible.sync="dialogLimitStop" v-if="dialogLimitStop">
        <limit-stop
          :iccidMark="iccidMark"
          @close="dialogLimitStop = false"
        ></limit-stop>
      </el-dialog>
      <el-dialog title="流量详单" :visible.sync="dialogFlowDetail" v-if="dialogFlowDetail">
        <flow-detail :iccidMark="iccidMark"></flow-detail>
      </el-dialog>
      <el-dialog title="变更过期时间" :visible.sync="dialogUpdateExpire" v-if="dialogUpdateExpire">
        <update-expire :cardId="cardId" :iccidMark="iccidMark" @refresh="refresh(true)"></update-expire>
      </el-dialog>
      <el-dialog title="转新卡" :visible.sync="dialogToNewCard" v-if="dialogToNewCard">
        <tonew-card
          :iccidMark="iccidMark"
          @close="dialogToNewCard = false"
        ></tonew-card>
      </el-dialog>
      <el-dialog title="增减流量" :visible.sync="dialogModifyFlow" v-if="dialogModifyFlow"> 
        <change-flow
          :curInfor="data"
          @close="dialogModifyFlow = false"
        ></change-flow>
      </el-dialog>
      <el-dialog title="切换量级级别" :visible.sync="dialogSwitch" v-if="dialogSwitch"> 
        <el-form label-position="right" label-width="100px" v-loading="switchLoading">
            <el-form-item label="虚量选择:" required>
                <el-radio-group v-model="strategyType">
                    <!-- <el-radio label="1">A</el-radio>
                    <el-radio label="2">B</el-radio> -->
                    <el-radio v-for="(item, index) in w_defaultStrategy" :key="index" :label="item.dictValue">{{item.groupName}}</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="sureSwitch">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogSwitch=false">取消</el-button>
        </div>
      </el-dialog>
      <el-dialog title="备注" :visible.sync="dialogRemark" v-if="dialogRemark"> 
        <el-form v-model="formRemark" label-position="right" label-width="100px">
            <el-form-item label="ICCID:" required>
                <el-input readonly v-model="formRemark.iccidMark"></el-input>
            </el-form-item>
            <el-form-item label="备注:" required>
                <el-input  type="textarea" autosize v-model="formRemark.remark"></el-input>
            </el-form-item>
        </el-form>  
        <div class="align-right">
            <el-button type="primary" class="themed-button" @click="sureRemark">确定</el-button>
            <el-button type="primary" class="themed-button" @click="dialogRemark=false">取消</el-button>
        </div>
      </el-dialog>

      <el-dialog title="设置闸值" :visible.sync="dialogGateSetting" v-if="dialogGateSetting">
        <gate-setting
          :iccidMark="iccidMark" :flowStop="flowStop"
          @close="dialogGateSetting = false"
        ></gate-setting>
      </el-dialog>
       <el-dialog title="白名单设置" :visible.sync="dialogWhiteVoice" v-if="dialogWhiteVoice">
        <white-voice :iccidMark="iccidMark" :phone="data.phone" @close="dialogWhiteVoice = false"  ></white-voice>
      </el-dialog>
      <el-dialog title="单卡限速" :visible.sync="dialogSingleSpeed" v-if="dialogSingleSpeed">
        <single-Speed :iccidMark="iccidMark"  @close="dialogSingleSpeed = false"  ></single-Speed>
      </el-dialog>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
import AddPackage from "./cardBillDetail/addPackage"
import LimitStop from "./cardBillDetail/limitStop"
import FlowDetail from "./cardBillDetail/flowDetail"
import UpdateExpire from "./cardBillDetail/updateExpire"
import TonewCard from "./cardBillDetail/toNewCard"
import ChangeFlow from "./cardBillDetail/changeFlow"
import GateSetting from "./cardBillDetail/gateSetting"
import ToNewCard from "./cardBillDetail/toNewCard.vue";
import WhiteVoice from "./cardBillDetail/whiteVoice.vue"
import SingleSpeed from "./cardBillDetail/singleSpeed.vue"


import {changeVirtualStrategy,findCardInfo, getPackageAll,activate_,cardRecovery_,cardStop_,limitStopRecovery_,setThreshold_,deviceCardBound_,tamperRecovery_,noLoopcard_,allowRealname_,openVoice_,delOnlyLoop_,isRealName,statusModify,cardRemarkUpdate,getRemarkByIccid,findSingleCardStopByIccid,mealAdvanceLife,cancelDxDisconnected,unboundRealName,returnToZero,extraStatus,clearHistory} from "@/api/cardManagement/cardBillDetail.js";

import { messageBox, confirmBox } from "@/utils/common.js";
import loading from "@/utils/Loading.js";
import Utils from '@/utils/utils.js'
import {mapGetters,mapMutations} from 'vuex'
import dictGroup from '@/mixins/dictGroup.js'

export default {
  name:'cardBillDetail',
  mixins:[dictGroup],
  data() {
    return {
      fullscreenLoading:false,
      res: {},
      data: {},
      shortIccid:'',
      noLoop:'',
      accountEntity: [],
      cardLiftList: [],
      nextCardLifeList: [],
      tianDjbCardLifes: [],

      dialogAddPackage: false,
      addPackageData: {},

      dialogLimitStop: false,
      dialogFlowDetail: false,
      dialogUpdateExpire: false,
      dialogToNewCard: false,
      dialogModifyFlow:false,
      dialogSwitch:false,
      dialogWhiteVoice:false,
      strategyType:'1',
      switchLoading:false,
      isReal:0,
      totalFlowId:'',
      surplusFlowId:'',
      dialogRemark:false,
      formRemark:{
        iccidMark:'',
        remark:''
      },
      dialogGateSetting:false,
      flowStop:'',
      dialogSingleSpeed:false,
      isLimitStop:"",
      isAloneLimitStop:"",
      ExtraMsg:"",
      StopThreshold:""
    }
  },
  computed: {
    ...mapGetters(
      ['iccidOrPhone','status','type',]
    ),
     getCutStatus(){
     let statusStr="-"
     if(this.ExtraMsg!=""){
        if(this.ExtraMsg=="不支持该接口"){
          statusStr="不支持查询"
        }else{
           statusStr="查询错误"
        }
     }else{
      if(this.isLimitStop&&this.isAloneLimitStop){
          if(this.StopThreshold){
          //  statusStr="断网值"+this.StopThreshold+",已达量断网,已单独断网"
          statusStr=`断网值${this.StopThreshold}M<br>
          已达量断网<br>
          已单独断网`
          }else{
             statusStr=`断网值空<br>
                   已达量断网<br>
                   已单独断网`
          }
      }else if(this.isLimitStop&&!this.isAloneLimitStop){
            if(this.StopThreshold){
           statusStr=`断网值${this.StopThreshold}M<br>
           已达量断网<br>
           未单独断网`
          }else{
             statusStr=`断网值空<br>
             已达量断网<br>
             未单独断网`
          }
      }else if(!this.isLimitStop&&this.isAloneLimitStop){
            if(this.StopThreshold){
           statusStr=`断网值${this.StopThreshold}M<br>
           未达量断网<br>
           已单独断网`
          }else{
             statusStr=`断网值空<br>
             未达量断网<br>
             已单独断网`
          }
      }else {
         if(this.isLimitStop.toString()=="false"&&this.isAloneLimitStop.toString()=="false"){
            if(this.StopThreshold){
           statusStr=`断网值${this.StopThreshold}M<br>
                     未达量断网<br>
                     未单独断网`
          }else{
             statusStr=`断网值空<br>
             未达量断网<br>
             未单独断网`
          }
         }
        
      }
     }

     return statusStr
    },
    curStrategyFormat(){
      let curStrategy = this.res.curStrategy
      if(curStrategy && curStrategy!=="") {
        if(isNaN(curStrategy)){
          var addStr = "";
          var pairs = curStrategy.split(";")
          var last = 0
          var idx = 0
          var total_count = pairs.length;
          for (var pair of pairs) {
              idx = idx + 1;
              var arr = pair.split(",")
              if (idx === total_count && arr[0] < 100) {
                  addStr = addStr+last+"%以上增加"+arr[1]+"%;<br>";
              } else {
                  addStr = addStr+last+"% ~ "+arr[0]+"%部分增加"+arr[1]+"%;<br>";
              }
              last = arr[0]
          }
          return addStr

        }else{
          return curStrategy + "%"
        }

      }else {
          return "未设置"
      }
    },
    isShow(){
      if (this.data.accountName && (this.data.accountName.startsWith("常州移动") || this.data.accountName.startsWith("重庆移动"))) {
            return false
        }else{
          return true
        }
    },
    yunyingstatusspan(){
      // if(this.data.category=='1' || this.data.category=='2' || this.data.category=='3' || this.data.category=='6'|| this.data.category=='4' || this.data.category=='5' || this.data.category=='7'|| this.data.category=='8'){
      //   return this.res.yunyingstatus
      // }else{
      //   return " "
      // }
      let flag = false
      this.w_ACCOUNT_.forEach(item => {
        if(item.dictValue == this.data.category){
          flag = true
        }
      })
      return flag ? this.res.yunyingstatus : ''
    },
    deviceOnline(){
      // if(this.data.category == '1'){
      if(this.data.category == this._getDictValue('DX_',this.w_ACCOUNT_)){
        return this.res.deviceOnline
      }else{
        return "运营商不支持在线状态查询"
      }
    },
    voiceRemaining(){
      return (this.data.voiceSize - parseInt(this.data.totalBytesCntVoice)).toFixed(0) + "分钟"
    },
    voiceUsed(){
      let flowCnt_voice = parseInt(this.data.totalBytesCntVoice);
      if(flowCnt_voice && flowCnt_voice!=""){
          flowCnt_voice = parseFloat(flowCnt_voice).toFixed(0);
      }
      return flowCnt_voice + "分钟"
    },
    origVoiceUsed(){
      let orginTotalBytesCntVoice = this.data.orginTotalBytesCntVoice;
      if(orginTotalBytesCntVoice && orginTotalBytesCntVoice!=""){
          orginTotalBytesCntVoice = parseFloat(orginTotalBytesCntVoice).toFixed(0);
      }
      return orginTotalBytesCntVoice + "分钟"
    },
    importTime() {
      //导入时间
      let createDateTime = this.data.createDateTime + "";
      return (
        createDateTime.substr(0, 4) +
        "-" +
        createDateTime.substr(4, 2) +
        "-" +
        createDateTime.substr(6, 2) +
        " " +
        createDateTime.substr(8, 2) +
        // ":" +
        // createDateTime.substr(8, 2) +
        ":" +
        createDateTime.substr(10, 2) +
        ":" +
        createDateTime.substr(12, 2)
      );
    },
    currentMeal() {
      //当前套餐
      console.log(this.cardLiftList,this.nextCardLifeList)
      let msg = "";
      if (this.cardLiftList.length > 0) {
        msg+="当前生效套餐<br>"
        this.cardLiftList.forEach((item,index) => {
          let effictDate= ""
          if(item.startDate||item.expireDate){
         effictDate=item.startDate.substring(0, 10) +"至"+item.expireDate.substring(0, 10)
          }else{
            effictDate="-"
          }
          if(index==1){
            msg+="待生效套餐<br>"
          }
          
          msg +=
            item.mealName + "&nbsp;&nbsp;&nbsp; <strong>有效周期:</strong> "+effictDate
            + "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> "+
            item.flowSize +
            "MB " +
            item.voiceSize +
            "分钟<br>";
        });
      }
      if (this.nextCardLifeList.length > 0) {
        //  msg+="待生效套餐<br>"
        this.nextCardLifeList.forEach((item) => {
         
          msg +=
            item.mealName +
            " " +
            "(次月生效) " + "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> "+
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }
      if (this.tianDjbCardLifes.length > 0) {
        this.tianDjbCardLifes.forEach((item) => {
          msg +=
            item.mealName +
            " " +
            "(次月生效) " + "&nbsp;&nbsp;&nbsp; <strong>容量: </strong> "+
            item.flowSize +
            "MB" +
            "<br/>";
        });
      }

      return msg;
    },
    gparams() {
      let params = {};
      params.iccidOrPhone = this.iccidMark
      params.setMealId = this.data.setMealId
      params.isChild = this.data.isChild
      params.codeChild = this.data.codeChild
      params.cardType = this.data.category
      params.status = this.data.status
      params.type = this.data.type
      return params
    },
    iccidMark() {
      let params = this.$route.query
      let iccidMark = params.iccidOrPhone
      if(!params.iccidOrPhone){
          iccidMark = this.iccidOrPhone
      }
      return iccidMark
    },
    cardId() {
      return this.data.id;
    },
  },
  mounted() {
    this.fullscreenLoading = true
    this._findCardInfo(false)
    this._getRemarkByIccid()
    // this.getExtraStatus()
  },
  methods: {
    ...mapMutations(
        ["SET_ICCID_FOR_TFJ"]
    ),

     formatTime(time){
             return Utils.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(time))
         },

    //退货清零
     returnToZero(){
         this.$confirm('执行退货清零后，将清除该卡的所有数据，并且不可恢复，确定要执行吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           let params = {};
      params.iccid = this.iccidMark
      returnToZero(params).then((res)=>{
         this.$message({
							showClose: true,
							message: '退货清零成功',
							type: 'success',
						})
      }).catch((error)=>{
          this.$message({
							showClose: true,
							message: '退货清零失败',
              type: 'warning',
						})
      })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退货清零操作'
          });          
        });

     
    },

    unbindRealName(){ //实名解绑
      unboundRealName({iccid:this.iccidMark}).then(res=>{
        messageBox(this,'操作成功!')
        this._findCardInfo(true)
      })
    },

    cancelDxDiscon(){  //取消单独断网
      cancelDxDisconnected({iccidMark:this.iccidMark}).then((res)=>{
        messageBox(this,'操作成功!')
        this._findCardInfo(true)
      })
    },
    advanceLife(){ //套餐提前生效
      mealAdvanceLife({iccidMark:this.iccidMark}).then(res=>{
        messageBox(this,'操作成功!')
        this._findCardInfo(true)
      })
    },
    gateSetting(){//单卡闸值设置
    findSingleCardStopByIccid({iccidMark:this.iccidMark}).then((res)=>{
      this.dialogGateSetting = true
      this.flowStop = res.flowStop
    })
      
    },
    remarkDetail(){ //备注
      this.dialogRemark = true
      this.formRemark.iccidMark = this.data.iccidMark
    },
    sureRemark(){
      if(this.formRemark.remark == ''){
        messageBox(this,'备注不能为空')
        return
      }
      if(this.formRemark.remark.length > 150){
        messageBox(this,'备注最大长度不能超过150字符')
        return
      }
      cardRemarkUpdate(this.formRemark).then((res)=>{
        messageBox(this,'修改成功')
        this.dialogRemark = false
      })
    },
    changeVirtual(){ //切换量级级别
      this.dialogSwitch = true
    },
    sureSwitch(){
      if(this.strategyType == this.res.strategyType){
        this.dialogSwitch = false
        return
      }
      let params = {
        iccidMark:this.data.iccidMark,
        strategyType:this.strategyType 
      }
      this.switchLoading = true
      changeVirtualStrategy(params).then((res)=>{
        this.dialogSwitch = false
        this.switchLoading = false
        messageBox(this,'切换虚量成功')
        this._findCardInfo(true)
      })
    },
    lifeCycleChange(){ //转正使用
      statusModify(this.gparams).then((res)=>{
        messageBox(this,'操作成功!')
        this._findCardInfo(true)
      })
    },
    delOnlyLoop() {//删除仅轮询
      this.fullscreenLoading = true
      delOnlyLoop_(this.gparams).then((res) => {
        messageBox(this, "操作成功")
        this._findCardInfo(true)
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
    openVoice() { //开通语音(GD)
      openVoice_(this.gparams).then((res)=>{
            messageBox(this, "操作成功")
            this._findCardInfo(true)
          })
      // if(this.accountEntity.category==="7" && ((this.accountEntity.secondCategory||"")==="1" || (this.accountEntity.secondCategory||"") ==="3")) {
      //     openVoice_(this.gparams).then((res)=>{
      //       messageBox(this, "操作成功")
      //       this._findCardInfo()
      //     })
      // } else {
      //     alertx("非CTBoss移动卡，不支持此操作！");
      // }
    },
    allowRealname() {//允许实名(GD)

      allowRealname_({iccidOrPhone:this.iccidMark}).then((res)=>{
            messageBox(this, "操作成功")
            this._findCardInfo(true)
        })

      // if (
      //   this.accountEntity.category === "7" && ((this.accountEntity.secondCategory || "") === "1" || (this.accountEntity.secondCategory || "") === "3")
      // ) {
      //   allowRealname_({iccidOrPhone:this.iccidMark}).then((res)=>{
      //       messageBox(this, "操作成功")
      //       this._findCardInfo()
      //   })
      // } else {
      //   messageBox(this, "非CTBoss移动卡，不支持此操作！")
      // }
    },
    whiteNubmerSet() {//白名单设置  是否有语言判断
    if(!!this.accountEntity.hasVoice)
      // if(this.accountEntity.category == this._getDictValue('XYD_',this.w_ACCOUNT_) && this.accountEntity.secondCategory == this._getDictValue('OneLink',this.w_secondCaterogy_yd)) 
      {
          // this.$router.push({
          //         path: '/historyRecord/voiceWhitelist',
          //         query: {
          //             iccid: this.iccidMark
          //         }
          // })
          this.dialogWhiteVoice = true
      } else {
          messageBox(this,"该卡不支持此操作！")
      }
    },
    modifyFlow() {  //增减流量
      this.dialogModifyFlow = true
    },
    noLoopcard() {  //不轮询网卡
      if (this.res.noLoop) {
        messageBox(this, "该卡已经是不轮询状态。");
        return
      }
      confirmBox(this, "添加成功后该卡将不执行停复机操作同时会出现流量，状态不更新等状态，请谨慎操作！",'操作提示',this._noLoopcard_)
      
    },
    tamperRecovery() { //窜卡复机
      this.fullscreenLoading = true
      tamperRecovery_(this.gparams).then((res) => {
          if(res.system_result_message_key){
        messageBox(this,res.system_result_message_key)
        }
        this._findCardInfo(true)
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      })
    },
    singleLimit() { //单卡限速
     this.dialogSingleSpeed = true
    },
    orderRecord() {  //往期订单
      this.$router.push({
        path: "/cardManagement/orderRecord",
        query: {
          iccidMark: this.iccidMark
        },
      })
    },
    stopRecoveryRecord() { ///停复机记录
      this.SET_ICCID_FOR_TFJ(this.iccidMark)
      this.$router.push({
        path: "/cardManagement/tingfujiRecord",
        query: {
          iccidMark: this.iccidMark,
        },
      })
    },
    deviceCardBound() {
      //机卡绑定
      let params = Object.assign(this.gparams, {
        iccidMark: this.data.iccidMark,
      });
      deviceCardBound_(params).then((res) => {
        messageBox(this, "绑定成功")
        this._findCardInfo(true)
      });
    },
    toNewCard() {
      //转新卡
      this.dialogToNewCard = true;
    },
    setThreshold() {
      //更新阈值
      this.fullscreenLoading = true
      setThreshold_(this.gparams).then((res) => {
         if(res.system_result_message_key){
        messageBox(this,res.system_result_message_key)
        }
        this._findCardInfo(true);
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
    updateExpire() {
      //更改过期时间
      this.dialogUpdateExpire = true;
    },
    flowDetail() {
      //流量详单
      this.dialogFlowDetail = true;
    },
    limitStopRecovery() {
      //达量断网复机
      this.fullscreenLoading = true
      limitStopRecovery_(this.gparams).then((res) => {
        this._findCardInfo(true)
        this.fullscreenLoading = true
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
    limitStop() {
      //达量断网
      if (this.data.category === this._getDictValue("DX_",this.w_ACCOUNT_)) {
        this.dialogLimitStop = true;
      } else {
        messageBox(this, "该卡不支持此操作！")
      }
    },
    cardStop() {
      //保号停机
      this.fullscreenLoading = true
      cardStop_(this.gparams).then((res) => {
        //添加提示
        if(res.system_result_message_key){
        messageBox(this,res.system_result_message_key)
        }
        this._findCardInfo(true)
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
    cardRecovery() {
      //保号复机
      this.fullscreenLoading = true
      cardRecovery_(this.gparams).then((res) => {
        this._findCardInfo(true);
        this.fullscreenLoading = false
      }).catch(()=>{
        this.fullscreenLoading = false
      });
    },
    activate() {
      //激活
      activate_({iccidMark:this.iccidMark}).then((res) => {
        if(res.system_result_message_key){
        messageBox(this,res.system_result_message_key)
        }
        this._findCardInfo(true);
      });
    },
    mealRecharge() {
      //套餐充值
      if (this.res.noRecharge) {
        messageBox(this,"该卡暂不支持充值。")
        return;
      }
      if (this.data.expireDate != "-") {
        confirmBox(this,"该卡已有套餐，确认是否继续充值?","操作提示",()=>{
          this._mealRecharge()
        });
      }else{
          this._mealRecharge()
      }
    },
    _isRealName(){
      isRealName({iccidOrPhone:this.data.iccidMark}).then((res)=>{
        if (res.isRealName == false){
            this.isReal = 2
        }else if (res.isRealName == true){
            this.isReal = 1
        }
      })
    },
    refresh(type) {
      this.fullscreenLoading = true
      this._findCardInfo(type)
       this._getRemarkByIccid()
    },
    clearHistory(){
      clearHistory({iccid:this.iccidMark}).then(res=>{
           if (res.system_result_key == '0') {
             messageBox(this,"清除成功")
                   this._findCardInfo(true)       
                        }else{
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
      })
    },
    _noLoopcard_(){
      noLoopcard_(this.gparams).then((res) => {
        messageBox(this,"添加成功。")
        this._findCardInfo(true)
      });
    },
    getExtraStatus(){
     let params = this.$route.query;
     console.log(params)
     let param={}
     if(!params.iccidOrPhone){
         param.iccid=this.iccidOrPhone
     }else{
       param.iccid=params.iccidOrPhone
     }
     console.log(param)
     extraStatus(param).then(res=>{

      console.log(res)
       if (res.system_result_key == '0') {
                    if(res.extra){
         this.isLimitStop = res.extra.isLimitStop=="false"?false:true
         this.isAloneLimitStop = res.extra.isAloneLimitStop=="false"?false:true
         this.StopThreshold  = res.extra.threshold
      }      
                        }else{
                          this.ExtraMsg=res.system_result_message_key
                          this.$message({
                                showClose: true,
                                message: res.system_result_message_key,
                                type: 'error'
                            });
                        }
     
         
     })
    },
   
    isOperatorFunciton(){
       this.getExtraStatus()
    },
    _findCardInfo(type) {
      let params = this.$route.query;
      let cache 
      if(!!type){
        cache = false//实时获取
      }else {
         cache = true//本地获取
      }
      if(!params.iccidOrPhone){
          params= {
            iccidOrPhone:this.iccidOrPhone,
            status:this.status,
            type:this.type,
            cache:cache
          }
      }else{
        params.cache = cache
      }
      findCardInfo(params).then((res) => {
        this.fullscreenLoading = false
        this.res = res
        this.data = res.entity
        this.accountEntity = res.accountEntity
        this.cardLiftList = res.cardLiftList
        this.nextCardLifeList = res.nextCardLifeList
        this.tianDjbCardLifes = res.tianDjbCardLifes
        this.shortIccid = res.shortIccid
        this.noLoop = res.noLoop
        this.strategyType = res.strategyType + ''

        let currSetmealFlow = 0
        let currSetmealName = ''
        if(this.cardLiftList){
          let isLongDiKa = false
          if(this.cardLiftList && this.cardLiftList.length>0) {
            for(var i=0;i<this.cardLiftList.length;i++) {
                // if(isLongDiKa==false && (this.cardLiftList[i].type==6||this.cardLiftList[i].type==23)) {
                  let typeValue = this._getDictValue('ZRLJTC_',this.w_packageType)
                if(isLongDiKa==false && (this.cardLiftList[i].type==typeValue)) {
                    isLongDiKa = true;
                    break;
                }

            }
          }
        }
        this.cardLiftList.forEach((item)=>{
          // if(item.type != 1 && item.type != 101){
          if(item.type != this._getDictValue('DJB_',this.w_packageType) && item.type != this._getDictValue('YYDJB_',this.w_packageType)){
              currSetmealFlow = item.flowSize;
              currSetmealName = item.mealName;
          }
        })

        if(currSetmealFlow == 0  &&!(!isLongDiKa && this.cardLiftList)){
          this.surplusFlowId = "∞ MB"
          this.totalFlowId = "∞ MB"
        }else{
          this.surplusFlowId =  parseFloat(this.data.flowSize - this.data.totalBytesCnt).toFixed(2) +'MB'
          this.totalFlowId = this.data.flowSize + 'MB'
        }

      });
    },
    _mealRecharge() {
      loading.show();
      let params = {};
      params.iccidOrPhone = this.data.iccidMark;
      params.setMealId = this.data.setMealId;
      params.isChild = this.data.isChild;
      params.codeChild = this.data.codeChild;
      getPackageAll(params).then((res) => {
        this.dialogAddPackage = true;
        this.addPackageData = res;
      });
    },
    _getRemarkByIccid(){
      // let params = this.$route.query
      // let iccidMark = params.iccidOrPhone
      // if(!params.iccidOrPhone){
      //     iccidMark = this.iccidOrPhone
      // }
      getRemarkByIccid({iccidMark:this.iccidMark}).then((res)=>{
        this.formRemark.remark = res.remark
      })
    },
    getRealNameType(realNameType){
      console.log(realNameType)
      let realNameTypeStr="-"
        if(realNameType=="4"){
         realNameTypeStr ="不用实名"
        }else if(realNameType=="1"){
          realNameTypeStr ="先实名后充值"
        }else if(realNameType=="2"){
      realNameTypeStr ="先充值后强制实名"
        }else if(realNameType=="3"){
      realNameTypeStr ="可选择下次实名"
        }
        return realNameTypeStr
    }
  },
  components: {
    AddPackage,
    LimitStop,
    FlowDetail,
    UpdateExpire,
    TonewCard,
    ChangeFlow,
    GateSetting,
    WhiteVoice,
    SingleSpeed,
  },
  
};
</script>

<style>
ul {
  list-style: none;
}
.cardBillDetail {
  position: relative;
  width: 100%;
  /* height: 100%; */
  background: #fff;
}
.cardBillDetail .card-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgba(7, 17, 27, 0.5);
}
.cardBillDetail .card-icon i {
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
}
.cardBillDetail .group {
  padding: 25px;
  background: #fff;
}
/* .cardBillDetail .group table {
  border-left: 1px solid #e7e7e7;
  border-top: 1px solid #e7e7e7;
} */
.cardBillDetail .group table td {
  height: 32px;
  line-height: 32px;
  /* border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7; */
  text-align: left;
}
.cardBillDetail .group .title {
  height: 32px;
  line-height: 32px;
}
.cardBillDetail ul > li {
  float: left;
  width: 125px;
  text-align: center;
  margin-right: 20px;
  margin-top: 10px;
}
</style>
